.app-container{
  min-height: 100vh;
  height: 100%;
}

.hero-title{
  font-family: 'Varela Round', sans-serif;
  position: absolute;
  font-size: 200px;
  left: 150px;
  top: 350px
}

.hero-logo{
  width: 50%;
  position: absolute;
  left: 50px;
  bottom: 150px;
}

.navbar{
  background-color: #000;
  z-index: 10;
}

.navbar-text{
}

.clickable{
  cursor: pointer;
}

.cursor-default{
  cursor: default;
}

.card-container{
  position: relative;
  width: 100%
}

.inspiration {
  position: fixed;
  padding: 10px;
  text-align: center;
  text-decoration: none;
  font-family: 'Gill Sans', sans-serif;
  font-size: 12px;
  color: #fff;
}

.strip-top {
  width: calc(5vw + 90px);
  transform: skewX(20deg) translateX(160px);
}
.strip-bottom {
  width: calc(50vw + 40px);
  transform: skewX(-15deg) translateX(90px);
}

.pay-card {
  width: 90vw;
  height: 250px;
}

.hr-dashed{
  border-top: 1px dashed black;
}

.btn-quantity{
  width: 25px;
  height: 25px;
  padding: 0;
  text-align: center;

}

.front,
.back {
  position: absolute;
  width: inherit;
  height: inherit;
  border-radius: 15px;
  color: #fff;
  text-shadow: 0 1px 1px rgba(0,0,0,0.3);
  box-shadow: 0 1px 10px 1px rgba(0,0,0,0.3);
  backface-visibility: hidden;
  background-image: linear-gradient(to right, #111, #555);
  overflow: hidden;
}
.front {
  transform: translateZ(0);
}


.strip-bottom,
.strip-top {
  position: absolute;
  right: 0;
  height: inherit;
  box-shadow: 0 0 10px 0px rgba(0,0,0,0.5);
  z-index: -1;
}
.strip-bottom-blue,
.strip-top-blue{
  background-image: linear-gradient(to bottom, #5ccbff, #007bb4);
}

.strip-bottom-LIFT,
.strip-top-LIFT{
  background-image: linear-gradient(to bottom, #ff5c5c, #bd1b1b);
}

.strip-bottom-gold,
.strip-top-gold{
  background-image: linear-gradient(to bottom, #fad488, #a1822c);
}
.strip-bottom {
  width: 200px;
  transform: skewX(-15deg) translateX(50px);
}
.strip-top {
  width: 180px;
  transform: skewX(20deg) translateX(50px);
}
.logo {
  position: absolute;
  top: 30px;
  right: 25px;
}
.investor {
  position: relative;
  top: 10px;
  left: 25px;
  text-transform: uppercase;
  z-index: 9999;
}
.chip {
  position: relative;
  top: 20px;
  left: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 40px;
  border-radius: 5px;
  background-image: linear-gradient(to bottom left, #ffecc7, #d0b978);
  overflow: hidden;
}
.chip .chip-line {
  position: absolute;
  width: 100%;
  height: 1px;
  background-color: #333;
}
.chip .chip-line:nth-child(1) {
  top: 13px;
}
.chip .chip-line:nth-child(2) {
  top: 20px;
}
.chip .chip-line:nth-child(3) {
  top: 28px;
}
.chip .chip-line:nth-child(4) {
  left: 25px;
  width: 1px;
  height: 50px;
}
.chip .chip-main {
  width: 20px;
  height: 25px;
  border: 1px solid #333;
  border-radius: 3px;
  background-image: linear-gradient(to bottom left, #efdbab, #e1cb94);
  z-index: 1;
}
.wave {
  position: relative;
  top: -20px;
  left: 100px;
}
.card-number {
  position: relative;
  margin: 0px 25px 0px;
  font-size: 23px;
  font-family: 'cc font', monospace;
}
.card-goto{
  z-index: 9999;
  position: relative;
}
.end {
  text-transform: uppercase;
  font-family: 'cc font', monospace;
}
.end .end-text {
  font-size: 9px;
  color: rgba(255,255,255,0.8);
}


@media only screen and (min-width: 450px) {

  .chip{
    top: 20px;
  }

  .wave{
    position: relative;
    top: -20px
  }

  .card-container{
    max-width: 400px;
  }
  .pay-card {
    max-width: 400px;
    height: 250px;
  }

  .card-number {
    position: relative;
    margin-left: 25px;
    margin-bottom: 0px;
    font-size: 23px;
    font-family: 'cc font', monospace;
  }
}

.no-link {
  color: inherit;
  text-decoration: none;
}

.bg-striped {
  background-color: #2c3034;
}

.navbar{
  /*   z-index should be above .close-navbar-toggler */
    z-index:10;
  }
  .close-navbar-toggler{
    position:absolute;
    top:0;
    left:0;
    height:100%;
    width:100%;
    z-index:1;
    cursor:pointer;
  }
  .close-navbar-toggler.collapsed{
    z-index:-1;
  }

.bg-gradient-1{
  background: rgb(0,123,180);
  background: linear-gradient(45deg, rgba(0,123,180,1) 0%, rgba(0,187,189,1) 100%);
}

.bg-gradient-2{
  background: rgb(0,187,189);
  background: linear-gradient(45deg, rgba(0,187,189,1) 0%, rgba(41,214,164,1) 100%);
}

.bg-gradient-3{
  background: rgb(41,214,164);
  background: linear-gradient(45deg, rgba(41,214,164,1) 0%, rgba(155,235,132,1) 100%);
}

@media (min-width: 991.98px) {
  .main {
  padding-left: 240px;
  }
}
/* Sidebar */
.sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  padding: 58px 0 0; /* Height of navbar */
  box-shadow: 0 2px 5px 0 rgb(0 0 0 / 5%), 0 2px 10px 0 rgb(0 0 0 / 5%);
  width: 240px;
  z-index: 3;
  }
  
  @media (max-width: 991.98px) {
  .sidebar {
  width: 100%;
  }
  }
  .sidebar .active {
  border-radius: 5px;
  box-shadow: 0 2px 5px 0 rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 / 12%);
  }
  
  .sidebar-sticky {
  position: relative;
  top: 0;
  height: calc(100vh - 48px);
  padding-top: 0.5rem;
  overflow-x: hidden;
  overflow-y: auto; /* Scrollable contents if viewport is shorter than content. */
  }

  .no-border{
    border: none
  }

  .active{
    background-color: #007BB4 !important;
  }


  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); /* Semi-transparante overlay */
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000; /* Zorg ervoor dat de overlay bovenop andere elementen ligt */
  }
  
  /* Style voor de modal content */
  .modal-content {
    background: #fff;
    border-radius: 8px;
    padding: 20px;
    max-width: 400px;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  }
  
  /* Sluitknop voor de modal */
  .modal-close {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    font-size: 20px;
    color: #333;
  }
  
  /* Voorkom dat de modal sluit als erop wordt geklikt */
  .modal-content, .modal-close {
    pointer-events: auto;
  }
  
  .success-modal {
    background: #4caf4fa1; /* Green background for success */
  }
  
  .error-modal {
    background: #f44336a1; /* Red background for error */
  }
  